/**
 * Requests
 */

import { useMutation, useQuery } from "react-query";
import { INetworkResponse } from "../models/NetworkResponse";

export async function getInstallations(): Promise<INetworkResponse> {
  const query = await fetch(`/api/installations`);
  return await query.json();
}

export async function toggleActivity(id: number): Promise<INetworkResponse> {
  const query = await fetch(`/api/installations?id=${id}`, {
    method: "PATCH",
  });
  return await query.json();
}

/**
 * Queries
 */

export const useGetInstallations = () =>
  useQuery("installations", getInstallations);

export const useToggleInstallationActivity = () =>
  useMutation(({ id }: { id: number }) => toggleActivity(id));
