export interface IDataTableColumn {
  label: string;
  key: string;
  customKeyHandler?: (data: any) => any;
}

export class DataTableColumn implements IDataTableColumn {
  label: string;
  key: string;
  customKeyHandler?: (data: any) => any;
  constructor(label: string, key: string, customKeyHandler?: (data:any) => any) {
    this.label = label;
    this.key = key;
    this.customKeyHandler = customKeyHandler;
  }
}
