import { IDataTableColumn } from "../../models/DataTableColumn";
import styled, { css } from "styled-components";
import { Loadbar } from "../../assets/icons/Loadbar";

interface IDataTable {
  data: any;
  columns: IDataTableColumn[];
  loading: boolean;
}

export default function DataTable(props: IDataTable) {
  const { data, columns, loading } = props;

  const getValue = (data: any, column: IDataTableColumn) => {
    if (!column.customKeyHandler) {
      return data[column.key] ?? "---";
    }
    const custom_key_value = column.customKeyHandler(data);
    return custom_key_value != null ? custom_key_value : "---";
  };

  if (!loading) {
    return (
      <StyledTableContainer>
        <StyledTable>
          <thead>
            <tr>
              {columns.map((column: IDataTableColumn, index: number) => {
                return <StyledTh key={`th_${index}`}>{column.label}</StyledTh>;
              })}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((data: any, index: number) => {
                return (
                  <StyledTr key={`tr_${index}`} otherRow={!(index % 2)}>
                    {columns.map((column: IDataTableColumn, index: number) => {
                      return (
                        <StyledTd key={`td_${index}`}>
                          {getValue(data, column)}
                        </StyledTd>
                      );
                    })}
                  </StyledTr>
                );
              })}
          </tbody>
        </StyledTable>
      </StyledTableContainer>
    );
  } else {
    return (
      <Loading>
        <Loadbar />
        Ladataan...
      </Loading>
    );
  }
}

const StyledTableContainer = styled.div`
  padding: 1rem 1rem 2rem;
  background-color: ${(props) => props.theme.body};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.primary.light};
  box-shadow: 2px 4px 8px 3px ${(props) => props.theme.boxShadow.purpleLight},
    0px 2px 4px ${(props) => props.theme.boxShadow.blackMiddle};
`;

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`;

const StyledTh = styled.th`
  padding-bottom: 1rem;
`;

const StyledTr = styled.tr<{ otherRow?: boolean }>`
  ${(props) =>
    props.otherRow &&
    css`
      background-color: ${(props) => props.theme.grey.lightTransparent};
    `}
`;

const StyledTd = styled.td`
  padding: 0.2rem;
  height: 40px;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  gap: 0.5rem;
  color: ${(props) => props.theme.primary.middle};
`;
