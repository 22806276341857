import moment from "moment";
import type { NextPage } from "next";
import { useSession, signIn, signOut } from "next-auth/react";
import { useState } from "react";
import styled from "styled-components";
import DataTable from "../components/Datatable";
import LoginScreen from "../components/LoginScreen";
import { DataTableColumn } from "../models/DataTableColumn";
import {
  ActionButton,
  StatusIndicator,
} from "../components/StyledButtons";
import { Sync } from "../assets/icons/Sync";
import { Loadbar } from "../assets/icons/Loadbar";
import { useGetInstallations, useToggleInstallationActivity } from "../queries/installations";
import SessionLayout from "../components/SessionLayout";

const Home: NextPage = () => {
  const { data: session } = useSession();

  const [loadingIds, setLoadingIds] = useState<number[]>([]);
  const installations = useGetInstallations();
  const toggle_activity = useToggleInstallationActivity();

  const getDates = (data: any) => {
    return moment(data.installation_date)
      .add(31, "days")
      .diff(moment(), "days");
  };

  const getDatesLeft = (data: any) => {
    if (data.has_been_activated) {
      return "Aktivoitu";
    }
    return getDates(data);
  };

  const handleToggle = async (id: number) => {
    setLoadingIds((old) => [...old, id]);
    toggle_activity.mutate({ id });
    setLoadingIds((old) =>
      old.filter((id_in_state: number) => {
        return id_in_state !== id;
      })
    );
  };

  const getActivateButton = (data: any) => {
    if (loadingIds.includes(data.id)) {
      return (
        <Loading>
          <Loadbar />
        </Loading>
      );
    }
    return (
      <ActionButton
        onClick={() => handleToggle(data.id)}
        action={!data.has_been_activated ? "activate" : "passivate"}
      >
        {data.has_been_activated ? "Passivoi" : "Aktivoi"}
      </ActionButton>
    );
  };

  const getStatus = (data: any) => {
    const dates_left = getDates(data);
    if (data.has_been_activated || dates_left > 0) {
      return <StatusIndicator active />;
    }
    return <StatusIndicator />;
  };

  const columns = [
    new DataTableColumn("Status", "", (data: any) => getStatus(data)),
    new DataTableColumn("Applikaation nimi", "name"),
    new DataTableColumn("Yrityksen nimi", "company_name"),
    new DataTableColumn("Yrityksen e-mail", "company_email"),
    new DataTableColumn("Päiviä jäljellä", "", (data: any) =>
      getDatesLeft(data)
    ),
    new DataTableColumn("Aktivoi", "", (data: any) => getActivateButton(data)),
  ];

  if (session) {
    return (
      <WrapperContainer>
        <NavContainer>
          {/* TODO: show ActionButton action="loading" while loading */}
          <ActionButton 
            action={"refresh"} onClick={() => installations.refetch()}>
            <Sync />
            Päivitä
          </ActionButton>
        </NavContainer>
        <DataTable
          loading={installations?.isLoading}
          data={(installations?.data?.error) ? [] : installations?.data?.response}
          columns={columns}
        />
      </WrapperContainer>
    );
  } else {
    return <div></div>
  }
};

export default Home;

const WrapperContainer = styled.div`
  margin: 0 auto;
  color: ${(props) => props.theme.text};
  height: 100%;
`;

const Loading = styled.div`
  padding-left: 1rem;
  gap: 0.5rem;
  color: ${(props) => props.theme.primary.light};
`;

const NavContainer = styled.div`
  margin: 0 0 2rem;
`;
